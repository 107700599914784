main {
    display: flex;
    margin: auto;
    width: 90vw;
}

aside {
    --aside-width: 6em;
    min-width: var(--aside-width);
    max-width: var(--aside-width);
}

article {
    flex-grow: 1;
}

section {
    display: flex;
}

section * {
    flex-grow: 1;
}

ol {
    margin: 0;
    padding-left: 1em;
}
