figure {
    margin: 0;
    padding: 0;
    --border-width: 2px;
    position: absolute;
    width: calc(1em - var(--border-width) * 2);
    height: calc(1em - var(--border-width) * 2);
    border: var(--border-width) solid black;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    content:attr(title);
}
